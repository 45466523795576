import axios from 'axios';
import * as CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_RESPONSE_SECRET; // Encryption secret key
const API = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });

// Axios response interceptor to decrypt responses automatically
API.interceptors.response.use(
    (response) => {
        if (response.data?.encryptedData) {
            try {
                const bytes = CryptoJS.AES.decrypt(response.data.encryptedData, SECRET_KEY);
                const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                return { ...response, data: decryptedData }; // Return decrypted response
            } catch (error) {
                return response; // Return original response if decryption fails
            }
        }
        return response; // Return as-is if no encryption
    },
    (error) => {
        return Promise.reject(error); // Handle errors properly
    }
);

// API requests
export const getCitation = (license_plate, tag_serial) =>
    API.get(`/citation/details?license_plate=${license_plate}&tag_serial=${tag_serial}`);

export const getTaggrNotices = (license_plate, tag_serial) =>
    API.get(`/taggr-notices/get-by-lp?license_plate=${license_plate}&tag_serial=${tag_serial}`);

export default API